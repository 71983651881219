import React, { createContext, useState } from 'react';

// Define the default value
const defaultContextValue = {
  isToggled: false,
  setToggleValue: (value:boolean) => {}, // Provide a default empty function
};

// Create a Context with the default value
export const TempContext = createContext(defaultContextValue);

// Create a provider component
export const TempProvider = ({ children }:any) => {
  const [isToggled, setIsToggled] = useState(false);

  // Function to set a specific boolean value
  const setToggleValue = (value:boolean) => setIsToggled(value);

  return (
    <TempContext.Provider value={{ isToggled, setToggleValue }}>
      {children}
    </TempContext.Provider>
  );
};
