import React from "react";
import {TempProvider} from "hooks/TempProvider";
import MyRouter from "routers/index";

function App() {
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <TempProvider>
        <MyRouter />
      </TempProvider>
    </div>
  );
}

export default App;
